$g-color:#409eff;
.msg-page {
  .title {
    color: #3f9cfc;
    border-bottom: 1px solid #3f9cfc;
    display: flex;
    align-items: flex-end;
    h2 {
      font-weight: normal;
      font-size: 18px;
      margin-right: 50px;
    }
    h3 {
      font-weight: normal;
      font-size: 14px;
    }
  }
  .mes-box {
    .msg-send {
      margin-bottom: 10px;
      button {
        margin-top: 5px;
      }
    }
    .msg-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(64, 157, 253, 0.2);
      line-height: 2;
      border-radius: 5px;
      padding: 0 10px;
      margin: 5px 0;
    }
    .custom-pagination{
      margin-top: 10px;
    }
    .msg-content {
      max-height: calc(100vh - 375px);
      overflow-y: auto;
      .msg-c-item {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid $g-color;
        img {
          width: 50px;
          height: 50px;
          border-radius: 8px;
        }
        .msg-c-main {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100px;
          .top {
            padding: 0 10px;
            div {
              .name {
                color: $g-color;
              }
              .floor {
                color: #aaa;
                font-size: 12px;
                margin-left: 10px;
              }
            }
            p.content {
              width: 1060px;
              font-size: 16px;
              padding: 5px;
              & *{
                white-space: wrap;
                word-break: break-word;
              }
            }
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            .time {
              font-size: 12px;
              color: #aaa;
              text-indent: 20px;
            }
            p {
              &>span {
                border: 1px solid $g-color;
                font-size: 12px;
                color: $g-color;
                border-radius: 5px;
                padding: 2px 5px;
                margin: 0 5px;
                cursor: pointer;
                span{
                  margin-right: 2px;
                }
              }
              .like,.reply {
                &.on {
                  background-color: $g-color;
                  color: #fff;
                }
              }
            }
          }
          // 评论区域
          .comment {
            margin-top: 5px;
            .get-more,.hidden-comment{
              border: 1px solid $g-color;
              font-size: 12px;
              color: $g-color;
              border-radius: 5px;
              padding: 2px 5px;
              margin: 0 5px;
              cursor: pointer;
              background-color: transparent;
              span{
                margin-right: 2px;
              }
            }
            .comment-item {
              display: flex;
              background-color: rgba(64, 157, 253, 0.1);
              padding: 5px;
              margin-bottom: 5px;
              img {
                width: 30px;
                height: 30px;
                border-radius: 5px;
              }
              .c-i-right {
                flex-grow: 1;
                margin-left: 5px;
                .com-top {
                  padding: 5px;
                  display: flex;
                  align-items: center;
                  span {
                    color: $g-color;
                    font-size: 14px;
                  }
                  b {
                    color: #ccc;
                    font-size: 12px;
                    margin: 0 5px;
                  }
                }
                p {
                  font-size: 12px;
                  padding: 10px;
                }
                .bottom {
                  display: flex;
                  justify-content: space-between;
                  .time {
                    font-size: 12px;
                    color: #aaa;
                    text-indent: 20px;
                  }
                  p {
                    span {
                      border: 1px solid $g-color;
                      font-size: 12px;
                      color: $g-color;
                      border-radius: 5px;
                      padding: 2px 5px;
                      margin: 0 5px;
                      cursor: pointer;
                    }
                    .like {
                      &.on {
                        background-color: $g-color;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
          .more {
            cursor: pointer;
            font-size: 12px;
            color: #ccc;
            span {
              &:hover {
                color: $g-color;
              }
            }
          }
          .reply-box {
            position: sticky;
            bottom: 0;
            .top{
              background-color: #fff;
            }
            &>p {
              font-size: 12px;
              line-height: 1.5;
              color: #ccc;
            }
            margin-top: 10px;
          }
        }
      }
    }
  }
}