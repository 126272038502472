.rich-text-editor{
    border: 1px solid #ddd;
    borderRadius: 4;
    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
        border-bottom: 1px solid #ddd;
        .button{
            padding: 4px 16px;
            background: #1677ff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}