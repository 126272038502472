// src/pages/TopicView/TopicView.scss
// 混合宏
@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topic-view {
  .auth-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #666;
  }

  .operation-header {
    @include flex-space-between;
    margin-bottom: 20px;

    .search-section {
      display: flex;
      gap: 10px;
      .type-selector {
        width: 200px;
      }

      .ant-btn {
        padding: 0 15px;
      }
    }

    .config-section {
      display: flex;
      align-items: center;
      gap: 20px;

      .progress-bar {
        width: 140px;
        .ant-progress-text {
          color: #52c41a;
        }
      }

      .limit-input {
        width: 120px;
      }

      .switch-group {
        display: flex;
        gap: 15px;

        span {
          display: inline-flex;
          align-items: center;
          gap: 5px;
          color: #666;
        }
      }

      .exam-button {
        margin-left: 10px;
        padding: 0 20px;
      }
    }
  }

  .topic-list {
    flex: 1;
    overflow: hidden;
    background: white;
    .topic-collapse {
      max-height: calc(100vh - 280px);
      overflow-y: scroll;
      .panel-title {
        @include flex-space-between;
        width: 100%;
        padding-right: 10px;

        span {
          flex: 1;
          margin-right: 15px;
        }
      }

      .keywords {
        margin: 10px 0;
        .keyword-tag {
          margin: 0 5px;
          border-radius: 12px;
        }
      }

      .result {
        padding: 12px;
        background: #f0f7ff;
        border-radius: 6px;
        line-height: 1.6;
      }
    }

    .pagination-wrapper {
      margin-top: 20px;
      text-align: center;
      @include flex-center;
      gap: 15px;

      .page-info {
        color: #666;
      }
    }

    .data-empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  // TopicView.scss
  .pagination-wrapper {
    margin-top: 20px;
    text-align: center;

    .custom-pagination {
      .ant-pagination-item-active {
        border-color: #1890ff;
      }

      .ant-pagination-item:hover {
        border-color: #40a9ff;
      }
    }
  }

}

