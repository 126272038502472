.learn-page {
    height: 100%; /* 通过样式实现高度 */
    .learn-header {
        background-color: #fff;
        border-radius: 4px;
        padding: 16px;
        justify-content: flex-start !important;
    }

    .main {
        background-color: #fff;
        border-radius: 4px;
        padding: 16px;
        .card {
            margin-bottom: 16px;
            height: 180px;
            .ant-card-head-title{
                font-size: 14px;
            }
        }
    }
    .empty {
        font-size: 16px;
        color: #999;
        text-align: center;
        padding: 32px;
    }
}

