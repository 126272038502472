.main-box{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 40px;
    .layout{
        min-height: calc(100vh - 40px);
        background-color: transparent;
        .header{
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: transparent;
            .menu{
                background-color: transparent;
                li{
                    color: #fff;
                    border-radius: 10px;
                    &:hover,&.ant-menu-item-selected{
                        color: #fff !important;
                        background-image: radial-gradient(#fff, var(--gc) 80%);
                        color: var(--gc) !important;
                        font-weight: bold;
                    }

                }
            }
        }

        .content{
            border-radius: 10px;
            overflow: hidden;
            background-color: white;
            padding: 20px;
        }
    }
    .footer{
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: white;
    }
}