:root {
    --gc: #409DFE;
}
body {

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #409DFE;
    background-image: repeating-linear-gradient(to right, var(--gc),  var(--gc) 5%,
    rgba(100, 100, 100, .3) 5%,
    rgba(230, 230, 230, .3) 5.2%,
    rgba(100, 100, 100, .3) 5.4%,
    #409DFE 5.4%,  var(--gc) 15%
    );
}

body, p, h1, h2, h3, h4, h5, h6, ul, ol {
    margin: 0;
}

ul, ol {
    padding: 0;
    list-style: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-card{
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
html, body {
    /*overflow-x: hidden; !* 隐藏水平滚动条 *!*/
}

.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}