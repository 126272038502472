// TechnologyView.scss
$primary-color: #1890ff;
$success-color: #52c41a;
$text-color: rgba(0, 0, 0, 0.85);
$secondary-text: #666;
$border-color: #f0f0f0;
$card-hover-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

.technology-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 220px); // 基础高度，会被内联样式覆盖

  // 搜索表单区域
  .search-form {
    margin-bottom: 24px;

    .ant-form-item {
      margin-right: 16px;
      margin-bottom: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    // 选择器统一宽度
    .ant-select {
      width: 160px;
    }

    // 搜索输入框
    .ant-input {
      width: 200px;
    }
  }

  // 数据列表区域
  .data-list {
    flex: 1;
    min-height: calc(100vh - 280px);
    margin-bottom: 24px;
    overflow-y: scroll;
    // 空状态提示
    .data-empty {
      margin: 48px 0;
    }
  }

  // 技术卡片样式
  .tech-card {
    transition: transform 0.2s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
      box-shadow: $card-hover-shadow;
    }
    img {
      background: #f5f5f5;
      object-fit: cover;
      object-position: left;

      // 当图片加载失败时显示
      &[src*="img.png"] {
        padding: 8px;
        background: #e8e8e8;
        border-radius: 4px;
      }
    }
    // 卡片头部布局
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid $border-color;

      // 标题区域
      .card-title {
        display: flex;
        align-items: center;
        max-width: 80%;
        img {
          height: 30px;
          margin-right: 12px;
          object-fit: cover;
          object-position: left;
          flex-shrink: 0;
        }

        // 标题文字
        .title-text {
          font-weight: 500;
          color: $text-color;
        }
      }

      // 详情按钮
      .ant-btn-link {
        padding: 0;
        color: $success-color;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    // 卡片内容
    .card-content {
      color: $secondary-text;
      line-height: 1.6;
      height: 40px;
      display: -webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  // 分页器样式
  .data-pagination {
    text-align: center;

    .ant-pagination-item {
      &-active {
        border-color: $primary-color;
      }

      &:hover a {
        color: $primary-color;
      }
    }

    .ant-pagination-item-link:hover {
      border-color: $primary-color;
    }
  }
}


