.image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-item {
    width: 40px;
    height: 40px;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    transition: border-color 0.3s;
    margin: 3px;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-item.active {
    border-color: #1890ff;
}
