.home-view {
  $carH: 440px;
  width: 100%;
  .r-title{
    display: flex;
    align-items: center;
    img{
      margin-right: 10px;
    }
  }
  .carousel {
    width: 100%;
    box-shadow: 0px 0px 5px 0px #eee;
    height: $carH;

    .carousel-item {
      width: 100%;
      height: $carH;

      img {
        width: 100%;
      }
    }
  }

  .card {
    box-shadow: 0px 0px 5px 0px #eee;
  }

  .article {
    height: $carH;

    .ant-card-body {
      height: calc(100% - 38px);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .article-item {
        display: flex;
        align-items: center;
        &:nth-child(1) .num{
          color: #fe2d46;
        }
        &:nth-child(2) .num{
          color:#f60 ;
        }
        &:nth-child(3) .num{
          color: #faa90e;
        }
        .num {
          display: block;
          flex-shrink: 0;
          width: 20px;
          color: #999;
        }

        p {
          text-align: left;
          a{
            color: #333;
            &:hover{
              color:  var(--gc);
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .daily{
    margin-top: 20px;
    .ant-card-body{
      display: flex;
      img{
        width: 100px;
        height: 100px;
        margin-right: 10px;
      }
      .right{
        .title{
          line-height: 50px;
          font-size: 20px;
          font-weight: bold;
          color: red;
        }
        .r-content{
          font-size: 12px;
          color: #999;
        }
      }
    }
  }

}